import Axios from 'axios';
import CircleColor from '@/entity/CircleColor';

async function callCreateGame(nbCells : number) {
    const httpClientApi = Axios.create();
    // use store
    const apiUrl = process.env.VUE_APP_URL;
    const url = `${apiUrl}/create-solo-game/${nbCells}`;
    return await httpClientApi.get(url);
}

async function callTryCombination(
    colorTests: Array<CircleColor>,
    idCrazyGame: number
) {
    const httpClientApi = Axios.create();
    // use store
    const apiUrl = process.env.VUE_APP_URL;
    const url = `${apiUrl}/try-combination/${idCrazyGame}`;
    return await httpClientApi.post(url, colorTests);
}

export { callCreateGame, callTryCombination };
