









































































import Vue from 'vue';
//import HelloWorld from './components/HelloWorld.vue';

export default Vue.extend({
    name: 'App',
    components: {
        //HelloWorld
    },

    data: () => ({
        links: [
            { id: 1, name: 'Accueil', route: `/` },
            { id: 2, name: 'Jouer', route: `/play/` }
            //{ id: 3, name: 'Règles', route: `/rules/` }
        ]
    })
});
