





























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
    createGame,
    tryCombination,
    getCrazyGameModule,
    setUseColor
} from '@/services/create.crazygamer.service';

@Component
export default class Play extends Vue {
    public links: string[] = ['Accueil', 'Jouer', 'Règles', 'Autres'];
    public nbCellsChoice: number[] = [3,4,5,6,7,8];
    public isCreated = false;
    public index = 0;
    public selectedNbCells = 4;
    //created() {
        // use localStorage for save id (refresh page, quit..)
        //if (this.getId == 0) createGame(this.selectedNbCells);
    //}

    public setSelectedNbCellsChoice(nbCellsChoice : number){
        this.selectedNbCells = nbCellsChoice;
    }

    public startNewGame() {
        createGame(this.selectedNbCells);
    }

    public validateCombination() {
        tryCombination();
        this.index = 0; // for send color after try
    }

    get getColors4() {
        return getCrazyGameModule().colors4;
    }

    get getColorUsed() {
        return getCrazyGameModule().colorUsed;
    }

    get getId() {
        return getCrazyGameModule().idCrazyGame;
    }

    get getCircleColors() {
        return getCrazyGameModule().colorTest2;
    }

    get getHistoCircleColors() {
        return getCrazyGameModule().histoColorTest;
    }

    // potentially useless
    get getCGModule() {
        return getCrazyGameModule();
    }

    public buildClassSmallColor(color: string) {
        return `icon-indication icon-${color}-circle`;
    }

    public buildClassColor(color: string) {
        return `icon icon-${color}-circle`;
    }

    public setCurrentIndexColor(index: number) {
        this.index = index;
    }

    public useColor(color: string) {
        if (!this.getCGModule.gameOver) {
            // if game is not finish
            if (this.index != this.getColorUsed) {
                setUseColor(color, this.index);
                this.index++;
            }
        }
    }
}
