import { AxiosResponse } from 'axios';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import CircleColor from '@/entity/CircleColor';
import Combination from '@/entity/Combination';
@Module
export default class CrazygameModule extends VuexModule {
    public idCrazyGame = 0;
    public colorUsed = 0;
    public colors4: string[] = [
        'green',
        'gray',
        'brown',
        'blue',
        'red',
        'orange',
        'purple',
        'pink'
    ];
    public histoColorTest: Array<Combination> = [];
    // REMOVE 2 FROM NAME
    public colorTest2: Array<CircleColor> = [];
    public gameWon!: boolean;
    public gameOver!: boolean;
    public maxStrokes = 0;
    public remainingStrokes = 0;
    public unicityColors = true;
    @Mutation
    public setIdCrazyGame(payload: AxiosResponse) {
        if (payload && payload.data) {
            this.idCrazyGame = payload.data.id;
            this.colorUsed = payload.data.combination.nbColorUsed;
            this.maxStrokes = payload.data.maxStrokes;
            this.remainingStrokes = payload.data.maxStrokes; // init with the maxStrokes
            // init fields
            this.colorTest2 = [];
            this.histoColorTest = [];
            this.gameWon = false;
            this.gameOver = false;

            // black starting colors
            for (let i = 0; i < this.colorUsed; i++) {
                const circleColor: CircleColor = new CircleColor(i, 'black');
                this.colorTest2[i] = circleColor;
                this.colorTest2.push();
            }
        }
    }

    @Mutation
    public setUseColorForIndex(payload: any) {
        //this.colorTest2.push(circleColor);
        //let present = false;
        //this.colorTest2.forEach(circleColor => {
        //    if (circleColor.color === payload.color) {
        //        present = true;
        //    }
        //});

        // good place for this ?
        this.unicityColors = true;

        // Add the color only if it is not already present
        this.colorTest2[payload.index] = new CircleColor(
            payload.index,
            payload.color
        );
        this.colorTest2.push();
    }

    @Mutation
    public initColorTest() {
        for (let i = 0; i < this.colorUsed; i++) {
            const circleColor: CircleColor = new CircleColor(i, 'black');
            this.colorTest2[i] = circleColor;
            this.colorTest2.push();
        }
    }

    @Mutation
    public showUnicityColorError(){
        this.unicityColors = false;
    }

    @Mutation
    public pushLastTestCombinationToHisto(json: any) {
        const histoCircleColor: Array<CircleColor> = [];

        // we subtract one stroke to the remaining strokes
        this.remainingStrokes = this.remainingStrokes-1;

        // need to make a copy of circleColor 's if not it's same object and will be impact on change
        this.colorTest2.forEach(element => {
            const circle: CircleColor = new CircleColor(
                element.indexColor,
                element.color
            );
            histoCircleColor.push(circle);
        });

        const nbColorPlaced = Number(json.nbColorPlaced);
        const nbExistingColor = Number(json.nbExistingColor);

        const histoCombination: Combination = new Combination(
            histoCircleColor,
            nbColorPlaced,
            nbExistingColor
        );

        this.histoColorTest.push(histoCombination);

        // TODO better job here
        const gameOver: boolean = json.gameOver !== undefined ? true : false;
        const gameWon: boolean = json.gameWon !== undefined ? true : false;
        if (gameOver) {
            if (gameWon) {
                this.gameWon = true;
                this.gameOver = true;
            } else {
                this.gameOver = true;
            }
        }
    }
}
