import {
    callCreateGame,
    callTryCombination
} from '@/api/crazygame/creategame-api';
import CrazygameModule from '@/module/crazygame.module';
import store from '@/store';

function getCrazyGameModule(): CrazygameModule {
    return store.state.crazyGame;
}

async function createGame(nbCells : number) {
    const response = await callCreateGame(nbCells);
    store.commit('setIdCrazyGame', response);
}

function setUseColor(color: string, index: number) {
    store.commit('setUseColorForIndex', { color, index });
}

async function tryCombination() {
    let isValid = true;
    let allColorAreUnique = true;

    // avoid to valid without colours
    getCrazyGameModule().colorTest2.forEach(circleColor => {
        if (circleColor.color === 'black') isValid = false;
    });

    const colorAlreadySeen: string[] = [];
    getCrazyGameModule().colorTest2.forEach(circleColor => {
        if (colorAlreadySeen.includes(circleColor.color)) {
            allColorAreUnique = false;
        }
        colorAlreadySeen.push(circleColor.color);
    });

    if(!allColorAreUnique){
        store.commit('showUnicityColorError');
    }

    if (isValid && allColorAreUnique) {
        const response = await callTryCombination(
            getCrazyGameModule().colorTest2,
            getCrazyGameModule().idCrazyGame
        );

        store.commit('pushLastTestCombinationToHisto', response.data);
        store.commit('initColorTest');
        //moveColorTestToBlack();

        // store.commit('setLastTestCombination', response)
    }
}

//function getCircleColorWithIndex(index: number) {
//    getCrazyGameModule().colorTest2.forEach(circleColor => {
//        if (circleColor.indexColor === index) return circleColor;
//    });
//}

export { createGame, getCrazyGameModule, setUseColor, tryCombination };
