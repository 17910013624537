import CircleColor from '@/entity/CircleColor';
export default class Combination {
    public circleColors: Array<CircleColor>;
    public nbColorPlaced: number;
    public nbExistingColor: number;

    constructor(
        circleColors: Array<CircleColor>,
        nbColorPlaced: number,
        nbExistingColor: number
    ) {
        this.circleColors = circleColors;
        this.nbColorPlaced = nbColorPlaced;
        this.nbExistingColor = nbExistingColor;
    }
}
